import * as React from "react"
import Layout from "../layouts/layout";

const ErrorPage = () => {
  return (
    <Layout pageTitle="404" pageType="notfound">
      Oeps! Deze pagina bestaat niet (meer){" "}      
      <span role="img" aria-label="Pensive emoji">
        😔
      </span>
    </Layout>
  )
}

export default ErrorPage
